import type { Location, NavigationType } from '@remix-run/react'
import { useLocation, useNavigationType } from '@remix-run/react'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'

type RouteMeta = Record<string | symbol | number, unknown>

type RouteChangeCallback = (
  prev: string | undefined,
  next: string,
  isBack: boolean
) => void

type RouteChangeContextType = {
  subscribe: (callback: RouteChangeCallback) => () => void
  setRouteMeta: (meta: RouteMeta) => void
  getPreviousRouteMeta: () => RouteMeta
}

const RouteChangeContext = createContext<RouteChangeContextType | null>(null)

export function RouteChangeProvider({ children }: { children: ReactNode }) {
  const callbacks = useRef(new Set<RouteChangeCallback>())
  const prevLocation = useRef<Location | null>(null)
  const isInitialLoad = useRef(true)
  const previousRouteMeta = useRef<RouteMeta>({})
  const currentRouteMeta = useRef<RouteMeta>({})

  function subscribe(callback: RouteChangeCallback) {
    callbacks.current.add(callback)

    return () => callbacks.current.delete(callback)
  }

  function setRouteMeta(meta: RouteMeta) {
    currentRouteMeta.current = { ...currentRouteMeta.current, ...meta }
  }

  function getPreviousRouteMeta() {
    return previousRouteMeta.current
  }

  const handleRouteChange = (
    location: Location,
    navigationType: NavigationType
  ) => {
    const isBack = navigationType === 'POP'
    const next = `${location.pathname}${location.search}${location.hash}`
    const prev = prevLocation.current
      ? `${prevLocation.current.pathname}${prevLocation.current.search}${prevLocation.current.hash}`
      : undefined

    callbacks.current.forEach((cb) => cb(prev, next, isBack))

    previousRouteMeta.current = currentRouteMeta.current
    currentRouteMeta.current = {}

    prevLocation.current = location
    isInitialLoad.current = false
  }

  return (
    <RouteChangeContext.Provider
      value={{ subscribe, setRouteMeta, getPreviousRouteMeta }}>
      {children}
      <RouteChangeHandler onRouteChange={handleRouteChange} />
    </RouteChangeContext.Provider>
  )
}

function RouteChangeHandler({
  onRouteChange,
}: {
  onRouteChange: (location: Location, navigationType: NavigationType) => void
}) {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    onRouteChange(location, navigationType)
  }, [location, navigationType])

  return null
}

export function useRouteChange() {
  const context = useContext(RouteChangeContext)

  if (!context) {
    throw new Error('useRouteChange must be used within a RouteChangeProvider')
  }

  return context.subscribe
}

export function useRouteMeta() {
  const context = useContext(RouteChangeContext)

  if (!context) {
    throw new Error('useRouteMeta must be used within a RouteChangeProvider')
  }

  return {
    getPreviousRouteMeta: context.getPreviousRouteMeta,
    setRouteMeta: context.setRouteMeta,
  }
}
